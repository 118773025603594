<template>
  <div class="sign">
    <div class="sign__container">
      <h2 class="sign__title">
        Восстановление пароля
      </h2>
      <p class="sign__text sign__text--center sign__text--mb">
        Вспомнили пароль?
        <router-link
          to="/users"
          class="sign__text-link"
        >
          Войти
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.33325 0.666641L10.6666 3.99998M10.6666 3.99998H1.33325H10.6666ZM7.33325 7.33331L10.6666 3.99998L7.33325 7.33331Z"
              stroke="#6764FF"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </p>

      <Form
        v-slot="{ errors }"
        class="sign__form"
        @submit="checkForm"
      >
        <!-- E-mail -->
        <vInput
          id="email"
          v-model="email"
          :value="email"
          placeholder="E-mail"
          :required="true"
          rule="required|mail|max:254"
          :error="USER_ERROR"
        />
        <!-- Recaptcha -->
        <vue-recaptcha
          v-show="showRecaptcha"
          ref="vueRecaptcha"
          site-key="6Ldqy-cUAAAAAPUTV7PRmVrVjhcW1g2VmHUhguaF"
          size="normal"
          theme="light"
          hl="ru"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
        />
        <br>
        <br>
        <vButton
          type="submit"
          :disabled="
            Object.keys(errors).length > 0 ||
              email.length < 1 ||
              !recaptchaVerifiedSuccess
          "
        >
          Восстановить
        </vButton>
      </Form>
      <img
        src="~@/assets/images/logo.svg"
        class="sign__logo"
        alt="logo"
      >
      <p class="sign__copyright">
        &copy; powered by tapper 2021
      </p>
    </div>
  </div>

  <!-- Modal Wait -->
  <vModalSuccess
    v-if="success"
    :close-bth="false"
    theme="light"
  >
    <template #img>
      <img
        src="~@/assets/images/forget.png"
        alt=""
        style="margin-bottom: 30px"
      >
    </template>
    <template #title>
      Новый пароль был выслан вам на почту
    </template>
    <template #description>
      Через 2 секунды вы будете перенаправлены на страницу авторизации
    </template>
  </vModalSuccess>
</template>

<script>
import { MODE } from '@/constants'
import vueRecaptcha from 'vue3-recaptcha2'
import { Form } from 'vee-validate'
import { mapGetters, mapActions } from 'vuex'
import vModalSuccess from '@/components/modals/v-modal-success'
import vInput from '@/components/v-input'
import vButton from '@/components/v-button'
export default {
  name: 'ForgetPasswordPage',
  components: {
    vInput,
    vButton,
    Form,
    vModalSuccess,
    vueRecaptcha,
  },
  data() {
    return {
      email: '',
      success: false,
      showRecaptcha: true,
      recaptchaVerifiedSuccess: false,
    }
  },
  computed: {
    ...mapGetters('user', ['USER_ERROR']),
  },
  mounted() {
    if (MODE === 'STAGING' || MODE === 'DEV') {
      ;(this.showRecaptcha = false), (this.recaptchaVerifiedSuccess = true)
    }
    this.success = false
  },
  methods: {
    ...mapActions('user', ['FORGET_PASSWORD']),
    async checkForm() {
      await this.FORGET_PASSWORD(this.email)
      if (this.USER_ERROR.length < 1) {
        this.success = true
        setTimeout(() => {
          this.email = ''
          this.$router.push('/users')
        }, 2000)
      }
    },
    recaptchaVerified(response) {
      this.recaptchaVerifiedSuccess = true
    },
  },
}
</script>
